export const contacts = {
    title: "Contacts",
    location: 'Situé au 1200 logements derrière le maquis "TITIS"',
    hours: "Ouvert du Lundi au Vendredi de 8h à 17h et samedi de 9h à 12h",
    url: "www.fasocred.com",
    po_box: "09 BP 1223 Ouagadougou 09",
    phone: '+226 25 36 21 49'
}


